import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Footer.module.css';

const Footer = () => {
  const { t, i18n } = useTranslation();

  const currentYear = new Date().getFullYear();

  const langs = {
    en: { nativeName: 'English' },
    fr: { nativeName: 'Français' }
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.copyright}>Copyright &copy; {currentYear}, {t('footer.copyright')}</div>
      <div>
        {Object.keys(langs).map((lang) => (
          <button
            key={lang}
            className={styles.button}
            style={{ fontWeight: i18n.language === lang ? 'bold' : 'normal' }}
            type="submit"
            onClick={() => i18n.changeLanguage(lang)}
          >
            {langs[lang].nativeName}
          </button>
        ))}
      </div>
    </footer>
  );
};

export default Footer;
