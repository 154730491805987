import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleLeft, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';

// Small images
import image0Small from '../../img/house-map-ground-level-480w.png';
import image1Small from '../../img/house-map-first-floor-480w.png';

// Medium images
import image0Medium from '../../img/house-map-ground-level-800w.png';
import image1Medium from '../../img/house-map-first-floor-800w.png';

// Large images
import image0Large from '../../img/house-map-ground-level-1280w.png';
import image1Large from '../../img/house-map-first-floor-1280w.png';

import styles from './HousePlansSlider.module.css';

const HousePlansSlider = () => {
  const { t } = useTranslation();

  const images = [
    {
      url: {
        large: image0Large,
        medium: image0Medium,
        small: image0Small,
      },
      alt: 'Ground level',
      caption: '1/2 Ground level',
    },
    {
      url: {
        large: image1Large,
        medium: image1Medium,
        small: image1Small,
      },
      alt: 'First floor',
      caption: '2/2 First floor',
    },
  ];

  const leftArrow = <FontAwesomeIcon className={styles.icon} icon={faChevronCircleLeft} color={"rgba(255, 255, 255, 0.7)"} size="3x" />;
  const rightArrow = <FontAwesomeIcon className={styles.icon} icon={faChevronCircleRight} color={"rgba(255, 255, 255, 0.7)"} size="3x" />;

  const [index, setIndex] = useState(0);

  const slideRight = () => {
    setIndex((index + 1) % images.length);
  };

  const slideLeft = () => {
    const nextIndex = index - 1;
    if (nextIndex < 0) {
      setIndex(images.length - 1);
    } else {
      setIndex(nextIndex);
    }
  };

  return (
    images.length > 0 && (
      <div className={styles.slider}>
        <img
          className={styles.image}
          srcSet={`${images[index].url.small} 480w, ${images[index].url.medium} 800w, ${images[index].url.large} 1280w`}
          sizes="(max-width: 600px) 480px, (max-width: 960px) 800px, 1280px"
          alt={t(`housePlansSlider.altText.img${[index]}`)}
        />
        <span className={`"fa-layers fa-fw" ${styles.button} ${styles.left}`} onClick={slideLeft}>{leftArrow}</span>
        <span className={`"fa-layers fa-fw" ${styles.button} ${styles.right}`} onClick={slideRight}>{rightArrow}</span>
        <p className={styles.caption}>{t(`housePlansSlider.caption.img${[index]}`)}</p>
      </div>
    )
  );
};

export default HousePlansSlider;
