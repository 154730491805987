import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import Contact from './components/pages/Contact';
import Details from './components/pages/Details';
import Home from './components/pages/Home';
import Whoops404 from './components/pages/Whoops404';

const App = () => {
  return (
    <Suspense fallback="...Loading">
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/details" element={<Details />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<Whoops404 />} />
        </Routes>
      </div>
    </Suspense>
  );
}

export default App;
