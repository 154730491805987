import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './Navbar.module.css';

const Navbar = () => {
  const { t } = useTranslation();

  return (
    <nav className={styles.navbar}>
      <div className={styles['nav-list']}>
        <Link className={styles['nav-item']} to="/">{t('navigation.item1')}</Link>
        <Link className={styles['nav-item']} to="/details">{t('navigation.item2')}</Link>
        <Link className={styles['nav-item']} to="/contact">{t('navigation.item3')}</Link>
      </div>
    </nav>
  );
};

export default Navbar;
