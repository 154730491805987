import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Container from '../layout/Container';
import Header from '../layout/Header';
import Navbar from '../layout/Navbar';
import Main from '../layout/Main';
import Footer from '../layout/Footer';
import styles from './Whoops404.module.css';

const Whoops404 = () => {
  const { t } = useTranslation();
  let location = useLocation();

  return (
    <Container>
      <Header>
        <Navbar />
      </Header>
      <Main>
        <h1 className={styles.title}>
          {t('whoops404.message')} {location.pathname}
        </h1>
      </Main>
      <Footer />
    </Container>
  );
};

export default Whoops404;
