import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleLeft, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';

// Small images
import image0Small from '../../img/bedroom1-480w.jpg';
import image1Small from '../../img/bedroom2-480w.jpg';
import image2Small from '../../img/bedroom3-480w.jpg';
import image3Small from '../../img/bedroom4-480w.jpg';
import image4Small from '../../img/dining-room-480w.jpg';
import image5Small from '../../img/kitchen-480w.jpg';
import image6Small from '../../img/sitting-room-480w.jpg';
import image7Small from '../../img/terrace-east-480w.jpg';
import image8Small from '../../img/terrace-east2-480w.jpg';
import image9Small from '../../img/terrace-west-480w.jpg';
import image10Small from '../../img/pergola-480w.jpg';
import image11Small from '../../img/orchard-480w.jpg';
import image12Small from '../../img/driveway-480w.jpg';
import image13Small from '../../img/bathroom-480w.jpg';
import image14Small from '../../img/shower-room-480w.jpg';
import image15Small from '../../img/house-for-sale-in-Apt-480w.jpg';
import image16Small from '../../img/garden-480w.jpg';
import image17Small from '../../img/view-of-land-1-480w.jpg';
import image18Small from '../../img/view-of-land-2-480w.jpg';

// Medium images
import image0Medium from '../../img/bedroom1-800w.jpg';
import image1Medium from '../../img/bedroom2-800w.jpg';
import image2Medium from '../../img/bedroom3-800w.jpg';
import image3Medium from '../../img/bedroom4-800w.jpg';
import image4Medium from '../../img/dining-room-800w.jpg';
import image5Medium from '../../img/kitchen-800w.jpg';
import image6Medium from '../../img/sitting-room-800w.jpg';
import image7Medium from '../../img/terrace-east-800w.jpg';
import image8Medium from '../../img/terrace-east2-800w.jpg';
import image9Medium from '../../img/terrace-west-800w.jpg';
import image10Medium from '../../img/pergola-800w.jpg';
import image11Medium from '../../img/orchard-800w.jpg';
import image12Medium from '../../img/driveway-800w.jpg';
import image13Medium from '../../img/bathroom-800w.jpg';
import image14Medium from '../../img/shower-room-800w.jpg';
import image15Medium from '../../img/house-for-sale-in-Apt-800w.jpg';
import image16Medium from '../../img/garden-800w.jpg';
import image17Medium from '../../img/view-of-land-1-800w.jpg';
import image18Medium from '../../img/view-of-land-2-800w.jpg';

// Large images
import image0Large from '../../img/bedroom1-1200w.jpg';
import image1Large from '../../img/bedroom2-1200w.jpg';
import image2Large from '../../img/bedroom3-1200w.jpg';
import image3Large from '../../img/bedroom4-1200w.jpg';
import image4Large from '../../img/dining-room-1200w.jpg';
import image5Large from '../../img/kitchen-1200w.jpg';
import image6Large from '../../img/sitting-room-1200w.jpg';
import image7Large from '../../img/terrace-east-1200w.jpg';
import image8Large from '../../img/terrace-east2-1200w.jpg';
import image9Large from '../../img/terrace-west-1200w.jpg';
import image10Large from '../../img/pergola-1200w.jpg';
import image11Large from '../../img/orchard-1200w.jpg';
import image12Large from '../../img/driveway-1200w.jpg';
import image13Large from '../../img/bathroom-1200w.jpg';
import image14Large from '../../img/shower-room-1200w.jpg';
import image15Large from '../../img/house-for-sale-in-Apt-1200w.jpg';
import image16Large from '../../img/garden-1200w.jpg';
import image17Large from '../../img/view-of-land-1-1200w.jpg';
import image18Large from '../../img/view-of-land-2-1200w.jpg';

import styles from './ImageSlider.module.css';

const ImageSlider = () => {
  const { t } = useTranslation();

  const images = [
    {
      url: {
        large: image15Large,
        medium: image15Medium,
        small: image15Small,
      },
      alt: 'House for sale in Apt, Luberon, Provence, Southern France',
      caption: '1/19 View of the house',
    },
    {
      url: {
        large: image16Large,
        medium: image16Medium,
        small: image16Small,
      },
      alt: 'View on the Luberon from the terrace',
      caption: '2/19 View on the Luberon from the terrace',
    },
    {
      url: {
        large: image0Large,
        medium: image0Medium,
        small: image0Small,
      },
      alt: 'Bedroom 1',
      caption: '3/19 Bedroom 1',
    },
    {
      url: {
        large: image1Large,
        medium: image1Medium,
        small: image1Small,
      },
      alt: 'Bedroom 2',
      caption: '4/19 Bedroom 2',
    },
    {
      url: {
        large: image2Large,
        medium: image2Medium,
        small: image2Small,
      },
      alt: 'Bedroom 3',
      caption: '5/19 Bedroom 3',
    },
    {
      url: {
        large: image3Large,
        medium: image3Medium,
        small: image3Small,
      },
      alt: 'Bedroom 4',
      caption: '6/19 Bedroom 4',
    },
    {
      url: {
        large: image4Large,
        medium: image4Medium,
        small: image4Small,
      },
      alt: 'Dining room',
      caption: '7/19 Dining room',
    },
    {
      url: {
        large: image5Large,
        medium: image5Medium,
        small: image5Small,
      },
      alt: 'Kitchen',
      caption: '8/19 Kitchen',
    },
    {
      url: {
        large: image6Large,
        medium: image6Medium,
        small: image6Small,
      },
      alt: 'Sitting room',
      caption: '9/19 Sitting room',
    },
    {
      url: {
        large: image7Large,
        medium: image7Medium,
        small: image7Small,
      },
      alt: 'Terrace (East)',
      caption: '10/19 Terrace (East)',
    },
    {
      url: {
        large: image8Large,
        medium: image8Medium,
        small: image8Small,
      },
      alt: 'Terrace (East)',
      caption: '11/19 Terrace (East)',
    },
    {
      url: {
        large: image9Large,
        medium: image9Medium,
        small: image9Small,
      },
      alt: 'Terrace (West)',
      caption: '12/19 Terrace (West)',
    },
    {
      url: {
        large: image10Large,
        medium: image10Medium,
        small: image10Small,
      },
      alt: 'Pergola',
      caption: '13/19 Pergola',
    },
    {
      url: {
        large: image11Large,
        medium: image11Medium,
        small: image11Small,
      },
      alt: 'Orchard',
      caption: '14/19 Orchard',
    },
    {
      url: {
        large: image12Large,
        medium: image12Medium,
        small: image12Small,
      },
      alt: 'Driveway',
      caption: '15/19 Driveway',
    },
    {
      url: {
        large: image13Large,
        medium: image13Medium,
        small: image13Small,
      },
      alt: 'Bathroom',
      caption: '16/19 Bathroom',
    },
    {
      url: {
        large: image14Large,
        medium: image14Medium,
        small: image14Small,
      },
      alt: 'Shower room',
      caption: '17/19 Shower room',
    },
    {
      url: {
        large: image17Large,
        medium: image17Medium,
        small: image17Small,
      },
      alt: 'View of land 1',
      caption: '18/19 View of land',
    },
    {
      url: {
        large: image18Large,
        medium: image18Medium,
        small: image18Small,
      },
      alt: 'View of land 2',
      caption: '19/19 View of land',
    },
  ];

  const leftArrow = <FontAwesomeIcon className={styles.icon} icon={faChevronCircleLeft} color={"rgba(255, 255, 255, 0.7)"} size="3x" />;
  const rightArrow = <FontAwesomeIcon className={styles.icon} icon={faChevronCircleRight} color={"rgba(255, 255, 255, 0.7)"} size="3x" />;

  const [index, setIndex] = useState(0);

  const slideRight = () => {
    setIndex((index + 1) % images.length);
  };

  const slideLeft = () => {
    const nextIndex = index - 1;
    if (nextIndex < 0) {
      setIndex(images.length - 1);
    } else {
      setIndex(nextIndex);
    }
  };

  return (
    images.length > 0 && (
      <div className={styles.slider}>
        <img
          className={styles.image}
          srcSet={`${images[index].url.small} 480w, ${images[index].url.medium} 800w, ${images[index].url.large} 1200w`}
          sizes="(max-width: 600px) 480px, (max-width: 960px) 800px, 1200px"
          alt={t(`imgSlider.altText.img${[index]}`)}
        />
        <span className={`"fa-layers fa-fw" ${styles.button} ${styles.left}`} onClick={slideLeft}>{leftArrow}</span>
        <span className={`"fa-layers fa-fw" ${styles.button} ${styles.right}`} onClick={slideRight}>{rightArrow}</span>
        <p className={styles.caption}>{t(`imgSlider.caption.img${[index]}`)}</p>
      </div>
    )
  );
};

export default ImageSlider;
