import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import './i18n';
import './reset.css';
import './index.css';
import './fonts/Montserrat-Regular.ttf';
import './fonts/Montserrat-Italic.ttf';
import './fonts/Montserrat-SemiBold.ttf';

ReactDOM.render(
  <Suspense fallback="...Loading">
    <React.StrictMode>
      <Router>
        <App />
      </Router>
    </React.StrictMode>
  </Suspense>,
  document.getElementById('root')
);
