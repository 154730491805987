import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../layout/Container';
import Header from '../layout/Header';
import Navbar from '../layout/Navbar';
import Main from '../layout/Main';
import ImageSlider from '../layout/ImageSlider';
import HousePlansSlider from "../layout/HousePlansSlider";
import Footer from '../layout/Footer';
import infoIcon from '../../icons/information-icon.svg';
import styles from './Details.module.css';

const Details = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Header>
        <Navbar />
      </Header>
      <Main>
        <div className={styles.heading}>
          <img className={styles.icon} src={infoIcon} alt="Information icon" />
          <h1 className={styles.title}>{t('details.title')}</h1>
        </div>
        <div className={styles['subtitle-container']}>
          <h2 className={styles.subtitle}>{t('details.subtitle')}</h2>
        </div>
        <div className={styles.content}>
          <div className={styles['image-container']}>
            <ImageSlider />
          </div>
          <div className={styles['text-container']}>
            <p className={styles.highlight}>
              {t('details.descriptionPart1')}
            </p>
            <p className={styles.description}>
              <span className={styles.highlight}>
                {t('details.descriptionPart2')}
              </span>
              {t('details.descriptionPart3')}
            </p>
            <p className={styles.description}>
              <span className={styles.highlight}>
                {t('details.descriptionPart4')}
              </span>
              {t('details.descriptionPart5')}
            </p>
            <p className={styles.description}>
              <span className={styles.highlight}>
                {t('details.descriptionPart6')}
              </span>
              {t('details.descriptionPart7')}
            </p>
            <p className={styles.highlight}>
              {t('details.descriptionPart8')}
            </p>
          </div>
          <div className={styles['image-container']}>
            <HousePlansSlider />
          </div>
        </div>
      </Main>
      <Footer />
    </Container>
  );
};

export default Details;
