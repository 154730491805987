import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../layout/Container';
import Header from '../layout/Header';
import Navbar from '../layout/Navbar';
import Main from '../layout/Main';
import Footer from '../layout/Footer';
import contactIcon from '../../icons/contact-icon.svg';
import styles from './Contact.module.css';
import imageSmall from '../../img/house-for-sale-in-Apt-480w.jpg';
import imageMedium from '../../img/house-for-sale-in-Apt-800w.jpg';

const Contact = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Header>
        <Navbar />
      </Header>
      <Main>
        <div className={styles.heading}>
          <img className={styles.icon} src={contactIcon} alt="Contact icon" />
          <h1 className={styles.title}>{t('contact.title')}</h1>
        </div>
        <div className={styles.content}>
          <div className={styles['image-container']}>
            <img
              className={styles.image}
              srcSet={`${imageSmall} 480w, ${imageMedium} 800w`}
              sizes="(max-width: 600px) 480px, 800px"
              alt={t('contact.imgAlt')}
            />
          </div>
          <div className={styles['text-container']}>
            <p className={styles.description}>{t('contact.description')}<a href="mailto:VillaLuberon@pm.me">VillaLuberon@pm.me</a></p>
          </div>
        </div>
      </Main>
      <Footer />
    </Container>
  );
};

export default Contact;
