import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../layout/Container';
import Header from '../layout/Header';
import Navbar from '../layout/Navbar';
import Main from '../layout/Main';
import Footer from '../layout/Footer';
import imageLarge from '../../img/house-for-sale-in-Apt-1200w.jpg';
import imageMedium from '../../img/house-for-sale-in-Apt-800w.jpg';
import imageSmall from '../../img/house-for-sale-in-Apt-480w.jpg';
import styles from './Home.module.css';

const Home = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Header>
        <Navbar />
      </Header>
      <Main>
        <h1 className={styles.title}>{t('home.title')}</h1>
        <h2 className={styles.subtitle}>{t('home.subtitle')}</h2>
        <div className={styles['image-container']}>
          <img
            className={styles.image}
            srcSet={`${imageSmall} 480w, ${imageMedium} 800w, ${imageLarge} 1200w`}
            sizes="(max-width: 600px) 480px, (max-width: 960px) 800px, 1200px"
            alt={t('home.imgAlt')}
          />
        </div>
        <p className={styles.price}>{t('home.price')}</p>
      </Main>
      <Footer />
    </Container>
  );
}

export default Home;
